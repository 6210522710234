import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import axios from 'src/axios';
import { DEFAULT_ERROR, FORGOT_PASSWORD_REJECTED, GET_USERS_REJECTED, GET_USER_REJECTED } from 'src/constants/snackbar';

import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '../../constants/table';
import { ErrorResponse } from '../types';
import {
  ForgotPasswordPayload,
  RoleOption,
  User,
  Users,
  UsersPayload,
  UsersState,
  resetPasswordPayload,
} from '../types/users';

export const initialState: UsersState = {
  user: null,
  userLoading: false,
  userError: '',
  users: {
    data: [],
    currentPage: 0,
    totalPages: 1,
    totalCount: 1,
    availableFilters: [],
  },
  usersLoading: false,
  usersError: '',
  usersFilters: '',
  usersPage: DEFAULT_PAGE,
  usersPageSize: DEFAULT_PAGE_SIZE,
  roles: [],
  availableCompanyAdmins: {
    data: [],
    currentPage: 0,
    totalPages: 1,
    totalCount: 1,
    availableFilters: [],
    loading: false,
    error: null,
  },
  availableLocationAdmins: {
    data: [],
    currentPage: 0,
    totalPages: 1,
    totalCount: 1,
    availableFilters: [],
    loading: false,
    error: null,
  },
};

export const forgotPassword = createAsyncThunk<unknown, ForgotPasswordPayload>(
  'users/forgot-password',
  async (data, { rejectWithValue }) => {
    try {
      await axios.post('/users/forgot-password', data);
    } catch (error) {
      const axiosError = error as AxiosError<ErrorResponse>;
      const message = axiosError.response?.data.message ?? FORGOT_PASSWORD_REJECTED;
      return rejectWithValue(message);
    }
  },
);

export const resetPassword = createAsyncThunk<unknown, resetPasswordPayload>(
  'users/reset-password',
  async (data, { rejectWithValue }) => {
    try {
      await axios.post('/users/reset-password', data);
    } catch (error) {
      const axiosError = error as AxiosError<ErrorResponse>;
      const message = axiosError.response?.data.message ?? DEFAULT_ERROR;
      return rejectWithValue(message);
    }
  },
);

export const getMyInfo = createAsyncThunk<User>('users/me', async (_, { rejectWithValue }) => {
  try {
    const response = await axios.get<User>('/users/me');
    return response.data;
  } catch (error) {
    const axiosError = error as AxiosError<ErrorResponse>;
    const message = axiosError.response?.data?.message ?? GET_USER_REJECTED;
    return rejectWithValue(message);
  }
});

export const getRoles = createAsyncThunk<RoleOption[]>('roles', async (_, { rejectWithValue }) => {
  try {
    const response = await axios.get<RoleOption[]>('/roles');
    return response.data;
  } catch (error) {
    const axiosError = error as AxiosError<ErrorResponse>;
    const message = axiosError.response?.data?.message ?? GET_USER_REJECTED;
    return rejectWithValue(message);
  }
});

export const getUsers = createAsyncThunk<Users, UsersPayload>('users', async (payload, { rejectWithValue }) => {
  try {
    const response = await axios.get<Users>('/users', { params: payload });
    return response.data;
  } catch (error) {
    const axiosError = error as AxiosError<ErrorResponse>;
    const message = axiosError.response?.data?.message ?? GET_USERS_REJECTED;
    return rejectWithValue(message);
  }
});

export const getAvailableCompanyAdminUsers = createAsyncThunk<Users, UsersPayload>(
  'users/company-admins/list',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get<Users>('/users/company-admins/list', { params: payload });
      return response.data;
    } catch (error) {
      const axiosError = error as AxiosError<ErrorResponse>;
      const message = axiosError.response?.data?.message ?? GET_USERS_REJECTED;
      return rejectWithValue(message);
    }
  },
);

export const getAvailableLocationAdminUsers = createAsyncThunk<Users, UsersPayload>(
  'users/location-admins/list',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get<Users>('/users/location-admins/list', { params: payload });
      return response.data;
    } catch (error) {
      const axiosError = error as AxiosError<ErrorResponse>;
      const message = axiosError.response?.data?.message ?? GET_USERS_REJECTED;
      return rejectWithValue(message);
    }
  },
);

export const updateUser = createAsyncThunk<User, Omit<User, 'status'>>(
  'users/update',
  async ({ id, ...data }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`/users/${id}`, { ...data });
      return response.data;
    } catch (error) {
      const axiosError = error as AxiosError<ErrorResponse>;
      const message = axiosError.response?.data?.message ?? DEFAULT_ERROR;
      return rejectWithValue(message);
    }
  },
);

export const deleteUser = createAsyncThunk<unknown, string>('users/delete', async (id, { rejectWithValue }) => {
  try {
    await axios.delete(`/users/${id}`);
  } catch (error) {
    const axiosError = error as AxiosError<ErrorResponse>;
    const message = axiosError.response?.data?.message ?? DEFAULT_ERROR;
    return rejectWithValue(message);
  }
});

export const deleteUsers = createAsyncThunk<unknown, string[]>(
  'users/delete/bulk',
  async (ids, { rejectWithValue }) => {
    try {
      await axios.delete('/users/bulk/delete', { data: { ids } });
    } catch (error) {
      const axiosError = error as AxiosError<ErrorResponse>;
      const message = axiosError.response?.data?.message ?? DEFAULT_ERROR;
      return rejectWithValue(message);
    }
  },
);

export const resendInvitations = createAsyncThunk<unknown, string[]>(
  'users/resend-inivitations',
  async (ids, { rejectWithValue }) => {
    try {
      await axios.post('/users/resend-invitations', { ids });
    } catch (error) {
      const axiosError = error as AxiosError<ErrorResponse>;
      const message = axiosError.response?.data?.message ?? DEFAULT_ERROR;
      return rejectWithValue(message);
    }
  },
);

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    resetUsers(state) {
      state.user = initialState.user;
      state.userLoading = initialState.userLoading;
      state.userError = initialState.userError;
      state.users = initialState.users;
      state.usersError = initialState.usersError;
      state.usersLoading = initialState.usersLoading;
      state.usersFilters = initialState.usersFilters;
    },
    setUsersFilters(state, action: PayloadAction<string>) {
      state.usersFilters = action.payload;
    },
    setUsersPage(state, action: PayloadAction<number>) {
      state.usersPage = action.payload;
    },
    setUsersPageSize(state, action: PayloadAction<number>) {
      state.usersPageSize = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(forgotPassword.pending, (state) => {
      state.userLoading = true;
      state.userError = '';
    });
    builder.addCase(forgotPassword.fulfilled, (state) => {
      state.userLoading = false;
      state.userError = '';
    });
    builder.addCase(forgotPassword.rejected, (state, action) => {
      state.userLoading = false;
      state.userError = action.payload as string;
    });
    builder.addCase(resetPassword.pending, (state) => {
      state.userLoading = true;
      state.userError = '';
    });
    builder.addCase(resetPassword.fulfilled, (state) => {
      state.userLoading = false;
      state.userError = '';
    });
    builder.addCase(resetPassword.rejected, (state, action) => {
      state.userLoading = false;
      state.userError = action.payload as string;
    });
    builder.addCase(getMyInfo.pending, (state) => {
      state.userLoading = true;
      state.userError = '';
    });
    builder.addCase(getMyInfo.fulfilled, (state, action: PayloadAction<User>) => {
      state.user = action.payload;
      state.userLoading = false;
      state.userError = '';
    });
    builder.addCase(getMyInfo.rejected, (state, action) => {
      state.user = initialState.user;
      state.userLoading = false;
      state.userError = action.payload as string;
    });
    builder.addCase(getUsers.pending, (state) => {
      state.usersLoading = true;
      state.usersError = '';
    });
    builder.addCase(getUsers.fulfilled, (state, action: PayloadAction<Users>) => {
      state.users = action.payload;
      state.usersLoading = false;
      state.usersError = '';
    });
    builder.addCase(getUsers.rejected, (state, action) => {
      state.users = { ...initialState.users, availableFilters: state.users.availableFilters };
      state.usersLoading = false;
      state.usersError = action.payload as string;
    });
    builder.addCase(getAvailableCompanyAdminUsers.pending, (state) => {
      state.availableCompanyAdmins.loading = true;
      state.availableCompanyAdmins.error = null;
    });
    builder.addCase(getAvailableCompanyAdminUsers.fulfilled, (state, action: PayloadAction<Users>) => {
      state.availableCompanyAdmins = {
        ...action.payload,
        loading: false,
        error: null,
      };
    });
    builder.addCase(getAvailableCompanyAdminUsers.rejected, (state, action) => {
      state.availableCompanyAdmins = {
        ...initialState.availableCompanyAdmins,
        availableFilters: state.availableCompanyAdmins.availableFilters,
        loading: false,
        error: action.payload as string,
      };
    });
    builder.addCase(getAvailableLocationAdminUsers.pending, (state) => {
      state.availableLocationAdmins.loading = true;
      state.availableLocationAdmins.error = null;
    });
    builder.addCase(getAvailableLocationAdminUsers.fulfilled, (state, action: PayloadAction<Users>) => {
      state.availableLocationAdmins = {
        ...action.payload,
        loading: false,
        error: null,
      };
    });
    builder.addCase(getAvailableLocationAdminUsers.rejected, (state, action) => {
      state.availableLocationAdmins = {
        ...initialState.availableLocationAdmins,
        availableFilters: state.availableLocationAdmins.availableFilters,
        loading: false,
        error: action.payload as string,
      };
    });
    builder.addCase(updateUser.pending, (state) => {
      state.usersLoading = true;
      state.usersError = '';
    });
    builder.addCase(updateUser.fulfilled, (state, action: PayloadAction<User>) => {
      const updatedUsers = state.users.data.map((user) => (user.id === action.payload.id ? action.payload : user));
      state.users = { ...state.users, data: updatedUsers };
      state.usersLoading = false;
      state.usersError = '';
    });
    builder.addCase(updateUser.rejected, (state, action) => {
      state.usersLoading = false;
      state.usersError = action.payload as string;
    });
    builder.addCase(getRoles.fulfilled, (state, action: PayloadAction<RoleOption[]>) => {
      state.roles = action.payload;
    });
  },
});

export const { resetUsers, setUsersFilters, setUsersPage, setUsersPageSize } = usersSlice.actions;

export default usersSlice.reducer;
