import axios, { AxiosError } from 'axios';

import { deleteCookie, getCookie } from './utils/cookie';

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

instance.interceptors.request.use(
  (config) => {
    const token = getCookie('token');
    if (token && config.url !== '/auth/login') {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      window.location.replace(window.location.origin + '/login');
      deleteCookie('token');
      return;
    }

    return Promise.reject(error);
  },
);

export default instance;
