import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import axios from 'src/axios';
import { BaseRow } from 'src/components/Table/types';
import { DEFAULT_ERROR, GET_LOCATION_REJECTED, GET_LOCATIONS_REJECTED } from 'src/constants/snackbar';

import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '../../constants/table';
import { ErrorResponse } from '../types';
import {
  Location,
  Locations,
  LocationsPayload,
  LocationsState,
  CreateLocationPayload,
  BulkDeleteParams,
  LocationQueryParams,
} from '../types/locations';

export const initialState: LocationsState = {
  locations: {
    data: [],
    currentPage: 0,
    totalPages: 1,
    totalCount: 1,
    availableFilters: [],
  },
  locationsLoading: false,
  locationsError: '',
  locationsFilters: '',
  locationsPage: DEFAULT_PAGE,
  locationsPageSize: DEFAULT_PAGE_SIZE,
  location: null,
  locationLoading: false,
  locationError: '',
};

export const getLocations = createAsyncThunk<Locations, LocationsPayload>(
  'locations',
  async ({ companyId, ...payload }, { rejectWithValue }) => {
    try {
      const response = await axios.get<Locations>(`/companies/${companyId}/locations`, { params: payload });
      return response.data;
    } catch (error) {
      const axiosError = error as AxiosError<ErrorResponse>;
      const message = axiosError.response?.data?.message ?? GET_LOCATIONS_REJECTED;
      return rejectWithValue(message);
    }
  },
);

export const getLocation = createAsyncThunk<Location, LocationQueryParams>(
  'location',
  async ({ companyId, locationId }, { rejectWithValue }) => {
    try {
      const response = await axios.get<Location>(`/companies/${companyId}/locations/${locationId}`);
      return response.data;
    } catch (error) {
      const axiosError = error as AxiosError<ErrorResponse>;
      const message = axiosError.response?.data?.message ?? GET_LOCATION_REJECTED;

      return rejectWithValue(message);
    }
  },
);

export const updateLocation = createAsyncThunk<Location, CreateLocationPayload & BaseRow>(
  'locations/update',
  async ({ id, companyId, ...data }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`/companies/${companyId}/locations/${id}`, { ...data });
      return response.data;
    } catch (error) {
      const axiosError = error as AxiosError<ErrorResponse>;
      const message = axiosError.response?.data?.message ?? DEFAULT_ERROR;
      return rejectWithValue(message);
    }
  },
);

export const deleteLocation = createAsyncThunk<unknown, { companyId: string; id: string }>(
  'locations/delete',
  async ({ id, companyId }, { rejectWithValue }) => {
    try {
      await axios.delete(`/companies/${companyId}/locations/${id}`);
    } catch (error) {
      const axiosError = error as AxiosError<ErrorResponse>;
      const message = axiosError.response?.data?.message ?? DEFAULT_ERROR;
      return rejectWithValue(message);
    }
  },
);

export const deleteLocations = createAsyncThunk<unknown, BulkDeleteParams>(
  'locations/delete/bulk',
  async ({ companyId, ids }, { rejectWithValue }) => {
    try {
      await axios.delete(`/companies/${companyId}/locations/bulk/delete`, { data: { ids } });
    } catch (error) {
      const axiosError = error as AxiosError<ErrorResponse>;
      const message = axiosError.response?.data?.message ?? DEFAULT_ERROR;

      return rejectWithValue(message);
    }
  },
);

const locationsSlice = createSlice({
  name: 'locations',
  initialState,
  reducers: {
    setLocationsFilters(state, action: PayloadAction<string>) {
      state.locationsFilters = action.payload;
    },
    setLocationsPage(state, action: PayloadAction<number>) {
      state.locationsPage = action.payload;
    },
    setLocationsPageSize(state, action: PayloadAction<number>) {
      state.locationsPageSize = action.payload;
    },
    resetLocations(state) {
      state.locations = initialState.locations;
      state.locationsError = initialState.locationsError;
      state.locationsFilters = initialState.locationsFilters;
      state.locationsLoading = initialState.locationsLoading;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLocations.pending, (state) => {
      state.locationsLoading = true;
      state.locationsError = '';
    });
    builder.addCase(getLocations.fulfilled, (state, action: PayloadAction<Locations>) => {
      state.locations = action.payload;
      state.locationsLoading = false;
      state.locationsError = '';
    });
    builder.addCase(getLocations.rejected, (state, action) => {
      state.locations = { ...initialState.locations, availableFilters: state.locations.availableFilters };
      state.locationsLoading = false;
      state.locationsError = action.payload as string;
    });
    builder.addCase(updateLocation.pending, (state) => {
      state.locationsLoading = true;
      state.locationsError = '';
    });
    builder.addCase(updateLocation.fulfilled, (state, action: PayloadAction<Location>) => {
      const updatedCompanies = state.locations.data.map((location) =>
        location.id === action.payload.id ? action.payload : location,
      );
      state.locations = { ...state.locations, data: updatedCompanies };
      state.locationsLoading = false;
      state.locationsError = '';
    });
    builder.addCase(updateLocation.rejected, (state, action) => {
      state.locationsLoading = false;
      state.locationsError = action.payload as string;
    });
    builder.addCase(getLocation.pending, (state) => {
      state.locationLoading = true;
      state.locationError = '';
    });
    builder.addCase(getLocation.fulfilled, (state, action: PayloadAction<Location>) => {
      state.location = action.payload;
      state.locationLoading = false;
      state.locationError = '';
    });
    builder.addCase(getLocation.rejected, (state, action) => {
      state.location = null;
      state.locationLoading = false;
      state.locationError = action.payload as string;
    });
  },
});

export const { setLocationsFilters, resetLocations, setLocationsPage, setLocationsPageSize } = locationsSlice.actions;

export default locationsSlice.reducer;
