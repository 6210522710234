import { LABEL_CONFIG_TYPE } from 'src/components/Table/types';
import { SortDirection } from 'src/types/sort';

export const LABEL_CONFIG: LABEL_CONFIG_TYPE = {
  invited: { label: 'Invited', color: '#0E90FF', backgroundColor: '#26ADFF29' },
  active: { label: 'Active', color: '#5E961A', backgroundColor: '#81C62729' },
  registered: { label: 'Registered', color: '#52555A', backgroundColor: '#7D7F8329' },
  disabled: { label: 'Disabled', color: '#5E961A', backgroundColor: '#81C62729' },
  draft: { label: 'Draft', color: '#52555A', backgroundColor: '#7D7F8329' },
  published: { label: 'Published', color: '#5E961A', backgroundColor: '#81C62729' },
  reserved: { label: 'Reserved', color: '#0E90FF', backgroundColor: '#26ADFF29' },
  picked_up: { label: 'Picked up', color: '#FFA500', backgroundColor: '#FFA50029' },
  cancelled: { label: 'Cancelled', color: '#FF0000', backgroundColor: '#FF000029' },
};

export const DATE_FORMAT = 'MMM D, YYYY';
export const DETAILS_DATE_FORMAT = 'DD/MM/YYYY';
export const PAYLOAD_DATE_FORMAT = 'YYYY-MM-DD';

export const ROWS_PER_PAGE_OPTIONS = [15, 25, 50];
export const DEFAULT_PAGE_SIZE = ROWS_PER_PAGE_OPTIONS[0];
export const DEFAULT_PAGE = 0;

export const DEFAULT_SORTING = { key: 'createdAt', direction: SortDirection.Desc };
