import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import axios from 'src/axios';
import { DEFAULT_ERROR, GET_DONATIONS_REJECTED, GET_DONATION_REJECTED } from 'src/constants/snackbar';
import { ErrorResponse } from 'src/redux/types';
import {
  Donation,
  DonationQueryParams,
  Donations,
  DonationsPayload,
  DonationsQueryParams,
  DonationsState,
} from 'src/redux/types/donations';

import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '../../constants/table';

export const initialState: DonationsState = {
  donations: {
    data: [],
    currentPage: 0,
    totalPages: 1,
    totalCount: 1,
    availableFilters: [],
  },
  donationsLoading: false,
  donationsError: '',
  donationsFilters: '',
  donationsPage: DEFAULT_PAGE,
  donationsPageSize: DEFAULT_PAGE_SIZE,
  donation: null,
  donationLoading: false,
  donationError: '',
};

export const getDonations = createAsyncThunk<Donations, DonationsPayload>(
  'donations',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axios.get<Donations>('/donations', { params: payload });

      return response.data;
    } catch (error) {
      const axiosError = error as AxiosError<ErrorResponse>;
      const message = axiosError.response?.data?.message ?? GET_DONATIONS_REJECTED;

      return rejectWithValue(message);
    }
  },
);

export const getDonation = createAsyncThunk<Donation, DonationQueryParams>(
  'donation',
  async ({ companyId, locationId, donationId }, { rejectWithValue }) => {
    try {
      const response = await axios.get<Donation>(
        `/companies/${companyId}/locations/${locationId}/donations/${donationId}`,
      );

      return response.data;
    } catch (error) {
      const axiosError = error as AxiosError<ErrorResponse>;
      const message = axiosError.response?.data?.message ?? GET_DONATION_REJECTED;

      return rejectWithValue(message);
    }
  },
);

export const deleteDonation = createAsyncThunk<unknown, DonationQueryParams>(
  'donation/delete',
  async ({ companyId, locationId, donationId }, { rejectWithValue }) => {
    try {
      await axios.delete(`/companies/${companyId}/locations/${locationId}/donations/${donationId}`);
    } catch (error) {
      const axiosError = error as AxiosError<ErrorResponse>;
      const message = axiosError.response?.data?.message ?? DEFAULT_ERROR;

      return rejectWithValue(message);
    }
  },
);

export const deleteDonations = createAsyncThunk<unknown, DonationsQueryParams>(
  'donation/delete/bulk',
  async ({ companyId, locationId, ids }, { rejectWithValue }) => {
    try {
      await axios.delete(`/companies/${companyId}/locations/${locationId}/donations/bulk/delete`, { data: { ids } });
    } catch (error) {
      const axiosError = error as AxiosError<ErrorResponse>;
      const message = axiosError.response?.data?.message ?? DEFAULT_ERROR;

      return rejectWithValue(message);
    }
  },
);

export const deleteDonationsAdmin = createAsyncThunk<unknown, string[]>(
  'donation/delete/bulk/admin',
  async (ids, { rejectWithValue }) => {
    try {
      await axios.delete('/donations/bulk/delete', { data: { ids } });
    } catch (error) {
      const axiosError = error as AxiosError<ErrorResponse>;
      const message = axiosError.response?.data?.message ?? DEFAULT_ERROR;

      return rejectWithValue(message);
    }
  },
);

const donationsSlice = createSlice({
  name: 'donations',
  initialState,
  reducers: {
    setDonationsFilters(state, action: PayloadAction<string>) {
      state.donationsFilters = action.payload;
    },
    setDonationsPage(state, action: PayloadAction<number>) {
      state.donationsPage = action.payload;
    },
    setDonationsPageSize(state, action: PayloadAction<number>) {
      state.donationsPageSize = action.payload;
    },
    resetDonations(state) {
      state.donations = initialState.donations;
      state.donationsError = initialState.donationsError;
      state.donationsFilters = initialState.donationsFilters;
      state.donationsLoading = initialState.donationsLoading;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDonations.pending, (state) => {
      state.donationsLoading = true;
      state.donationsError = '';
    });
    builder.addCase(getDonations.fulfilled, (state, action: PayloadAction<Donations>) => {
      state.donations = action.payload;
      state.donationsLoading = false;
      state.donationsError = '';
    });
    builder.addCase(getDonations.rejected, (state, action) => {
      state.donations = { ...initialState.donations, availableFilters: state.donations.availableFilters };
      state.donationsLoading = false;
      state.donationsError = action.payload as string;
    });
    builder.addCase(getDonation.pending, (state) => {
      state.donationLoading = true;
      state.donationError = '';
    });
    builder.addCase(getDonation.fulfilled, (state, action: PayloadAction<Donation>) => {
      state.donation = action.payload;
      state.donationLoading = false;
      state.donationError = '';
    });
    builder.addCase(getDonation.rejected, (state, action) => {
      state.donation = null;
      state.donationLoading = false;
      state.donationError = action.payload as string;
    });
  },
});

export const { setDonationsFilters, resetDonations, setDonationsPage, setDonationsPageSize } = donationsSlice.actions;

export default donationsSlice.reducer;
